import { ReferralSummaryData } from '@/app/hooks/useQueryUser'
import { Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { Affiliate, ReferralStatus } from '../FormTypes'
interface DownlineListProps {
  downline?: ReferralSummaryData['downline']
  downlineRate?: ReferralSummaryData['downlineRate']
}
const referralStatusList = Object.keys(ReferralStatus).filter((val) => isNaN(Number(val)))

const getRandomString = (length = 6) => Math.random().toString(20).substring(2, length)
const getRandomStatus = () => referralStatusList[Math.floor(Math.random() * 4)]
const getRows = (length = 25) => {
  const rows: Array<Affiliate> = []
  for (let i = 0; i < length; i++) {
    rows.push({
      name: `${getRandomString()} ${getRandomString()[0]}`,
      applying: Math.floor(Math.random() * Math.floor(Math.random() * 10)),
      submitted: Math.floor(Math.random() * Math.floor(Math.random() * 10)),
      approved: Math.floor(Math.random() * Math.floor(Math.random() * 10)),
      rejected: Math.floor(Math.random() * Math.floor(Math.random() * 10)),
    })
  }
  return rows
}

const DownlineList: React.FC<DownlineListProps> = ({ downline, downlineRate }) => {
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'applying', headerName: 'Applying', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'submitted', headerName: 'Submitted', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'approved', headerName: 'Approved', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'rejected', headerName: 'Inelligible', width: 125, align: 'center', headerAlign: 'center' },
  ]

  const data = useMemo(() => {
    if (!downline) {
      return []
    }
    let _downline: Array<Affiliate> = []
    const names = Object.getOwnPropertyNames(downline)
    names.forEach((name) => {
      _downline.push({ name, ...downline[name] })
    })
    return _downline
  }, [downline?.length])

  const testData: Affiliate[] = useMemo(() => {
    return getRows()
  }, [])

  let rowIndex = -1
  return data.length > 0 ? (
    <div className={'flex flex-1 flex-col'}>
      <DataGrid
        getRowId={(row) => {
          rowIndex++
          return rowIndex
        }}
        disableColumnFilter
        disableColumnMenu
        rows={data}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 50]}
      />
    </div>
  ) : (
    <Typography>Your downline is empty.</Typography>
  )
}

export default DownlineList
