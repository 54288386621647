'use client'
import { Paper, Typography, useTheme } from '@mui/material'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { PrequalifierData, UserFormProps } from './FormTypes'
// import {FormInputCheckbox} from './formComponents/FormCheckbox'
import Box from '@mui/material/Box'
import { useState } from 'react'
import ResumeButton from '../components/ResumeButton'
import useGTagOnRender, { trackEvent } from '../hooks/useGTagOnRender'

export const jobList = [
  '1099 Employee',
  'Independent Contractors',
  'Influencers',
  'Freelancers',
  'Subcontractors',
  'Consultants',
  'Ecommerce Pros',
  'Real Estate Agents',
  'Bookkeepers',
  'Photographers',
  'Designers',
  'Amazon Sellers',
  'Accountants',
  'Veterinarians',
  'Air BnB Hosts',
  'Doordashers',
  'Uber Drivers',
  'Task Rabbit',
  'Owner/Operators',
]

export const Prequalifier: React.FC<UserFormProps> = ({ onContinue }) => {
  const {
    handleSubmit,
    reset,
    formState: { errors },
    ...formMethods
  } = useForm<PrequalifierData>({
    defaultValues: {
      selfEmployed: false,
      positiveNet: false,
      missedTime: false,
    },
  })

  useGTagOnRender('prequalifier', 'prequalification_start')

  const [currentStep, setCurrentStep] = useState(0)

  const theme = useTheme()

  const onSubmit: SubmitHandler<PrequalifierData> = (data) => {
    if (!data.selfEmployed || !data.positiveNet || !data.missedTime) {
      trackEvent('prequalification_ineligible')
      setCurrentStep(4)
    } else {
      trackEvent('prequalification_finish')
      setCurrentStep(3)
    }
  }

  const resetForm = () => {
    formMethods.setValue('selfEmployed', false)
    formMethods.setValue('positiveNet', false)
    formMethods.setValue('missedTime', false)
    setCurrentStep(0)
  }

  const setSelfEmployed = (value: boolean) => {
    formMethods.setValue('selfEmployed', value)
    setCurrentStep(1)
  }

  const setPositiveNet = (value: boolean) => {
    formMethods.setValue('positiveNet', value)
    setCurrentStep(2)
  }
  const setMissedTime = (value: boolean) => {
    formMethods.setValue('missedTime', value)
    handleSubmit(onSubmit)()
  }

  const steps = [
    <div
      className="flex flex-1 flex-col gap-4 sm:gap-8 px-4 items-stretch prequal-survey-step py-4"
      style={{
        height: '80vh',
        minHeight: '300px',
        width: '100vw',
        maxWidth: 650,
      }}
      key={'prequal-survey-step-1'}
      id="prequal-survey-step-1">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Typography
          align={'center'}
          variant="h5"
          sx={{
            fontSize: '1.4rem !important',
            mt: 0,
            marginBottom: '6px !important',
            lineHeight: 1,
            fontWeight: 500,
          }}>
          Were you self employed
        </Typography>
        <Typography
          variant="h5"
          align={'center'}
          sx={{
            fontSize: '1.4rem !important',
            marginTop: '0 !important',
            lineHeight: 1,
            fontWeight: 500,
          }}>
          in 2020 or 2021?
        </Typography>
        <Typography
          variant="body1"
          align={'center'}
          sx={{
            lineHeight: 1,
            fontWeight: 500,
          }}>
          <i>
            This includes anyone with income from:
            <br />
            1099, independent contractor, gig worker, Single Member LLC, Sole Proprietorship
          </i>
        </Typography>
      </Box>

      {/*<h5 className="font-semibold text-center text-black text-md md:text-3xl">In 2020 or 2021, were you ever self*/}
      {/*    employed?</h5>*/}
      <div className="flex flex-row primary-button button" onClick={() => setSelfEmployed(true)}>
        <div className="flex flex-col justify-center items-center flex-1 ">Yes</div>
      </div>

      <div className="flex flex-row border rounded-md py-2 button previous" onClick={() => setSelfEmployed(false)}>
        <div className="flex flex-col justify-center items-center flex-1 text-black">No</div>
      </div>

      <div className="flex-1 flex flex-col justify-start overflow-y-auto">
        <div className="text-gray-700 underline mb-2 ">Including but not limited to:</div>
        <div className="flex flex-row flex-wrap gap-2">
          {jobList.map((p) => {
            return (
              <div key={p} className="bg-green-200 p-1 px-2 rounded-3xl text-gray-800 text-sm">
                {p}
              </div>
            )
          })}
        </div>
      </div>
    </div>,

    <div
      className="flex flex-1 flex-col gap-4 sm:gap-8 px-4 items-stretch prequal-survey-step py-4"
      key={'prequal-survey-step-2'}
      style={{
        height: '80vh',
        minHeight: '300px',
        width: '100vw',
        maxWidth: 650,
      }}
      id="prequal-survey-step-2">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Typography
          align={'center'}
          variant="h5"
          sx={{
            fontSize: '1.4rem !important',
            mt: 0,
            marginBottom: '6px !important',
            lineHeight: 1,
            fontWeight: 500,
          }}>
          Did you have positive self-employed
        </Typography>
        <Typography
          variant="h5"
          align={'center'}
          sx={{
            fontSize: '1.4rem !important',
            marginTop: '0 !important',
            lineHeight: 1,
            fontWeight: 500,
          }}>
          earnings in 2019, 2020 or 2021?
        </Typography>
      </Box>

      <div className="h-8" />
      <div className="flex flex-row primary-button button" onClick={() => setPositiveNet(true)}>
        <div className="flex flex-col justify-center items-center flex-1 ">Yes</div>
      </div>
      <div className="flex flex-row border rounded-md py-2 button previous" onClick={() => setPositiveNet(false)}>
        <div className="flex flex-col justify-center items-center flex-1 text-black">No</div>
      </div>
      <div className="flex flex-row border rounded-md pt-2 button previous" onClick={() => setPositiveNet(true)}>
        <div className="flex flex-col justify-center items-center flex-1 text-black">I don&apos;t know</div>
      </div>
      <Typography
        variant="body1"
        align={'center'}
        sx={{
          lineHeight: 1,
          fontWeight: 500,
        }}>
        <i>If you&apos;re unsure, click I don&apos;t know, our CPAs can help, no charge.</i>
      </Typography>
    </div>,
    <div
      className="flex flex-1 flex-col gap-4 sm:gap-8 px-4 items-stretch prequal-survey-step py-4"
      style={{
        height: '80vh',
        minHeight: '300px',
        width: '100vw',
        maxWidth: 650,
      }}
      key={'prequal-survey-step-3'}
      id="prequal-survey-step-3">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Typography
          align={'center'}
          variant="h5"
          sx={{
            fontSize: '1.4rem !important',
            mt: 0,
            marginBottom: '6px !important',
            lineHeight: 1,
            fontWeight: 500,
          }}>
          Did you miss self-employment work due to coronavirus or coronavirus related events
        </Typography>
        <Typography
          variant="h5"
          align={'center'}
          sx={{
            fontSize: '1.4rem !important',
            marginTop: '0 !important',
            lineHeight: 1,
            fontWeight: 500,
          }}>
          in 2020 or 2021?
        </Typography>
      </Box>

      <div className="flex flex-row primary-button button" onClick={() => setMissedTime(true)}>
        <div className="flex flex-col justify-center items-center flex-1 ">Yes</div>
      </div>
      <div className="flex flex-row border rounded-md py-2 button previous" onClick={() => setMissedTime(false)}>
        <div className="flex flex-col justify-center items-center flex-1 text-black">No</div>
      </div>
      <div className="flex-1 flex flex-col justify-start overflow-y-auto">
        <div className="text-lg text-gray-700">Examples of missed work include, but are not limited to:</div>
        <div className="flex flex-col gap-2">
          <ul>
            {[
              'Quarantine/lockdown orders from federal, state, or local governments',
              'Caring for a child whose daycare or school was closed due to COVID-19',
              'Having symptoms of COVID-19 or seeking a medical diagnosis including routine testing',
              'Experiencing side effects from the COVID-19 vaccine',
              'Caring for someone with COVID-19 symptoms',
            ].map((p) => {
              return (
                <li key={p} className="p-1 px-2 rounded-3xl text-sm">
                  {p}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>,
    <div
      className="flex flex-1 flex-col gap-4 sm:gap-8 px-4 items-stretch prequal-survey-step py-4"
      style={{
        height: '80vh',
        minHeight: '300px',
        width: '100vw',
        maxWidth: 650,
      }}
      key={'prequal-survey-step-4'}
      id="prequal-survey-step-4">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Typography
          align={'center'}
          variant="h5"
          sx={{
            fontSize: '1.6rem !important',
            mt: 0,
            marginBottom: '0px !important',
            lineHeight: 1,
            fontWeight: 500,
          }}>
          Congratulations!
        </Typography>
        <Typography
          align={'center'}
          variant="h5"
          sx={{
            fontSize: '1.4rem !important',
            marginTop: '10px !important',
            marginBottom: '0px !important',
            lineHeight: 1,
            fontWeight: 500,
          }}>
          Based off your answers you should be eligible.
        </Typography>
      </Box>

      <div>
        <strong>2020 credits expire on April 14th.</strong> Due to a large number of applications and the busy tax
        season we cannot gaurantee that we can file your 2020 return on time, unless you start soon.
      </div>
      <div>
        <strong>There is no out of pocket cost to you</strong>, and no obligation. Click the button begin.
      </div>
      <div className="flex flex-row py-2 flex-1 justify-center items-center">
        <button type="submit" onClick={() => onContinue()} className="button primary-button">
          <div>get started</div>
        </button>
      </div>
      <div className="italic text-sm">
        This is for a straight cash refund into your bank account from the IRS, not a loan like the PPP or grant like
        the EIDL.
      </div>
    </div>,
    <div
      className="flex flex-1 flex-col gap-4 pt-16 px-8 items-stretch prequal-survey-step"
      key={'prequal-survey-step-5'}
      id="prequal-survey-step-5">
      <h6 className="font-medium text-center">It does not look like you qualify for SETC credit.</h6>
      <div className="h-8" />
      <Typography
        align={'center'}
        variant="h5"
        sx={{
          fontSize: '1.4rem !important',
          marginTop: '10px !important',
          marginBottom: '0px !important',
          lineHeight: 1,
          fontWeight: 500,
        }}>
        It does not look like you qualify for SETC credit. If you believe this is an error please restart the questions.
      </Typography>
      <div className="flex flex-row py-2 flex-1 justify-center items-center">
        <button type="button" onClick={() => resetForm()} className="w-full bg-green-600 border rounded-md p-2">
          <div className="button text-white">Restart Eligibility Questions</div>
        </button>
      </div>
    </div>,
  ]

  return (
    <Box
      sx={{
        backgroundImage: `url('/large_wave_bg.jpg')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom center',
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.dark,
      }}>
      <Paper
        elevation={4}
        style={{
          // display: 'flex',
          // flexDirection: 'column',
          // alignItems: 'center',
          borderRadius: 16,
        }}>
        <div className="flex flex-1">{steps[currentStep]}</div>
        <div className={`flex flex-row justify-center p-4 ${currentStep >= 3 ? 'invisible' : ''}`}>
          <div className={`h-2 ${currentStep === 0 ? 'bg-purple-700' : 'bg-purple-300'} rounded-md w-16`} />
          <div className="w-1" />
          <div className={`h-2 ${currentStep === 1 ? 'bg-purple-700' : 'bg-purple-300'} rounded-md w-16`} />
          <div className="w-1" />
          <div className={`h-2 ${currentStep === 2 ? 'bg-purple-700' : 'bg-purple-300'} rounded-md w-16`} />
        </div>
      </Paper>
      <div className="flex flex-row justify-end items-end absolute top-0 right-2  pt-2">
        <ResumeButton buttonText="Login" />
      </div>
    </Box>
  )
}
