'use client'
import { userTokenAtom } from '@/app/atoms'
import useClipboard from '@/app/hooks/useCliipboard'
import useQueryUser, { ReferralSummaryData } from '@/app/hooks/useQueryUser'
import { devlog } from '@/app/utils'
import { LinkRounded } from '@mui/icons-material'
import { Box, Button, Card, Snackbar, Tab, Tabs } from '@mui/material'
import { atom, useAtom, useAtomValue } from 'jotai'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { FormInputCheckbox } from '../formComponents/FormCheckbox'
import DownlineList from './DownlineList'
import ReferralList from './ReferralList'
import { jobList } from '../Prequalifier'

enum ReferralTabs {
  'Summary',
  'Referrals',
  'Downline',
}

const _currentTab = atom(ReferralTabs.Summary)

export function ReferralSection({ trackShare }: { trackShare: () => void }) {
  const userToken = useAtomValue(userTokenAtom)
  const clipboard = useClipboard()
  const [copySnackOpen, setCopySnackOpen] = useState(false)
  const [currentTab, setCurrentTab] = useAtom(_currentTab)
  const { getUserReferralSummary } = useQueryUser()
  const [showAllJobs, setShowAllJobs] = useState(false)

  const {
    handleSubmit,
    reset,
    formState: { errors },
    ...formMethods
  } = useForm<{ termsAndConditions: boolean }>({
    defaultValues: {
      termsAndConditions: false,
    },
    mode: 'onChange',
  })

  const { data, dataUpdatedAt, refetch } = useQuery<QueryResponse<ReferralSummaryData>>(
    'referral-summary',
    async () => getUserReferralSummary(userToken),
    {
      enabled: !!userToken,
    }
  )

  const summary = useMemo(() => {
    if (dataUpdatedAt && data?.success) {
      return data.body
    }
  }, [dataUpdatedAt])

  const hasDownline = !!summary?.downlineRate && Object.getOwnPropertyNames(summary?.downline ?? []).length > 0

  const totalCount = Object.keys(summary?.summary || {}).reduce(
    (total, key) => total + ((summary?.summary as Record<string, number>)?.[key] || 0),
    0
  )

  const acceptTermsState = useMutation<
    { success: boolean; error: string | null; termsAndConditions?: boolean },
    void,
    { termsAndConditions: boolean }
  >(
    async () => {
      let queryUrl = `${process.env.NEXT_PUBLIC_WORKER}/referral/accept`
      try {
        const response = await fetch(queryUrl, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        })
        const data = await response?.json?.()
        if (!!data) {
          devlog('ReferralSection >> accept Terms')
          return { success: true, error: null, termsAndConditions: data.terms_accepted }
        }

        devlog('ReferralSection >> accept terms NOT response.ok', JSON.stringify({ response, userToken }, null, 2))
        return { success: false, body: null, error: 'NOT response.ok' }
      } catch (e) {
        return { success: false, body: null, error: JSON.stringify(e) }
      }
    },
    {
      onSuccess: (data) => {
        refetch()
      },
    }
  )

  const onSubmitTerms = (data: { termsAndConditions: boolean }) => {
    if (data.termsAndConditions) {
      acceptTermsState.mutate(data)
    }
  }

  // one card, three tabs
  // summary
  // referrals
  // downline (if present)
  if (!summary) {
    return null
  }

  if (!summary.terms_accepted) {
    return (
      <div className="flex flex-col justify-center items-center flex-1">
        <Card className="flex flex-col justify-start items-center p-2 md:p-8">
          <div className="flex justify-center items-center text-xl font-medium">Accept Agreement to continue</div>
          <div className="h-8" />
          <div style={{ maxHeight: 500 }} className="border border-solid border-gray-400 flex flex-col py-2">
            <div className="flex-1 overflow-y-scroll px-2">
              {agreementText(summary.referralAmountPerApp, summary.referralRateType)
                .split('\n')
                .map((line, i) => {
                  const isHeader = !line.includes(' ') || line.toUpperCase() === line
                  return (
                    <p
                      className={`${isHeader ? 'font-semibold text-center' : 'text-start'}`}
                      style={{ textIndent: isHeader ? 0 : 40 }}
                      key={i}>
                      {!isHeader ? '\t' : ''}
                      {line}
                    </p>
                  )
                })}
            </div>
          </div>
          <div className="h-8" />
          <div>
            <FormInputCheckbox
              rules={{ required: 'You must accept the Agreement to continue' }}
              name="termsAndConditions"
              label="Accept Agreement"
              {...formMethods}
            />
          </div>
          <div className="h-8" />
          <Box className="bottom-buttons" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmitTerms)}
              disabled={acceptTermsState?.isLoading}
              className="w-48">
              Continue
            </Button>
          </Box>
        </Card>
      </div>
    )
  }

  return (
    <div className="flex flex-col justify-start items-center">
      <div style={{ maxWidth: 960 }} className="flex flex-col justify-start items-stretch">
        {summary.downlineRefUrl ? (
          <>
            <div className="h-4" />
            <h3 className="text-center mb-3">Affiliate Referrals</h3>
            <Card className="flex flex-row justify-start items-center p-2 md:p-8">
              <div className="bg-green-900 w-12 h-12 rounded-full flex justify-center items-center">
                <LinkRounded className="text-white" />
              </div>
              <div
                className="flex-1 flex justify-center items-center font-bold text-md md:text-xl text-black cursor-auto hover:cursor-pointer"
                onClick={() => {
                  clipboard.copy(summary.downlineRefUrl)
                  setCopySnackOpen(true)
                  // trackShare()
                }}>
                {summary.downlineRefUrl}
              </div>
            </Card>
          </>
        ) : null}
        <h3 className="text-center mb-2">Refer Others</h3>
        <div className="text-center font-medium px-8">
          Earn <span className="text-black">{formatRate(summary.referralAmountPerApp, summary.referralRateType)}</span>{' '}
          for every self employed friend that you refer who&apos;s application is approved. All you have to do is share
          the link below and we&apos;ll take care of the rest.
        </div>
        {summary.referralUrl ? (
          <>
            <div className="h-4" />
            <h5 className="text-center mb-3">Referral URL</h5>
            <Card className="flex flex-row justify-start items-center p-2 md:p-8 gap-4">
              <div className="bg-green-900 w-12 h-12 rounded-full flex justify-center items-center">
                <LinkRounded className="text-white" />
              </div>
              <div
                className="flex-1 flex justify-center items-center font-bold text-md md:text-xl text-black cursor-auto hover:cursor-pointer"
                onClick={() => {
                  clipboard.copy(summary.referralUrl)
                  setCopySnackOpen(true)
                  trackShare()
                }}>
                {summary.referralUrl}
              </div>
            </Card>
            <div className="h-4" />
            <Card className="p-4 flex flex-col gap-4">
              <div className="text-lg font-semibold">Who do you know, who is a...</div>
              <div className="flex flex-row flex-wrap gap-2">
                {(showAllJobs ? jobList : jobList.filter((val, index) => index < 6)).map((p) => {
                  return (
                    <div key={p} className="bg-green-200 p-1 px-2 rounded-3xl text-gray-800 text-sm">
                      {p}
                    </div>
                  )
                })}
                <div
                  className="font-semibold text-sm text-purple-700 flex justify-start items-center"
                  onClick={() => setShowAllJobs(!showAllJobs)}>
                  See {showAllJobs ? 'Less' : 'More'}
                </div>
              </div>
              <div>
                Share with them and get{' '}
                <span className="text-black">{formatRate(summary.referralAmountPerApp, summary.referralRateType)}</span>
                , when they&apos;re approved!
              </div>

              <hr className="w-full" />
              <div className="text-lg text-medium">Not sure what to say?</div>
              <div
                className="p-2 bg-gray-700 text-white rounded"
                onClick={() => {
                  clipboard.copy(`There is a tax refund I missed, up to 32k. Half goes away in April. I found this site that did
              all the work for me: ${summary.referralUrl}`)
                  setCopySnackOpen(true)
                  trackShare()
                }}>
                There is a tax refund I missed, up to 32k. Half goes away in April. I found this site that did all the
                work for me: {summary.referralUrl}
              </div>
              <div
                className="flex justify-end font-semibold text-lg cursor-pointer"
                onClick={() => {
                  clipboard.copy(`There is a tax refund I missed, up to 32k. Half goes away in April. I found this site that did
              all the work for me: ${summary.referralUrl}`)
                  setCopySnackOpen(true)
                  trackShare()
                }}>
                <div className="bg-purple-700 text-white p-2 rounded-md px-8">Copy</div>
              </div>
            </Card>
          </>
        ) : null}

        <div className="h-16" />
        <div className={`flex flex-1 flex-col items-stretch`}>
          <div className={'flex flex-1 flex-col items-stretch'}>
            <Card
              className={`flex flex-col justify-start items-stretch border border-solid border-gray-300`}
              style={{ minWidth: 350, minHeight: currentTab !== ReferralTabs.Summary ? Math.min( 250 + (totalCount * 50), 800) : undefined }}>
              <div className={`flex justify-center`}>
                <Tabs
                  value={currentTab}
                  onChange={(e, value) => setCurrentTab(value)}
                  sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                  <Tab label="Summary" value={ReferralTabs.Summary} />
                  <Tab label="Referrals" value={ReferralTabs.Referrals} />
                  {hasDownline ? <Tab label="Downline" value={ReferralTabs.Downline} /> : null}
                </Tabs>
              </div>
              <div className="flex-1">
                <TabPanel value={ReferralTabs.Summary}>
                  <div className="flex-1 w-full">
                    <div>
                      <h4 className="text-center">Referred Applications</h4>
                      <div
                        className={
                          'grid grid-cols-2 md:grid-cols-5 justify-items-center' +
                          (currentTab !== ReferralTabs.Summary ? ' hidden' : '')
                        }
                        hidden={currentTab !== ReferralTabs.Summary}>
                        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                          <div className="font-bold text-base text-black">Started</div>
                          <div className="font-bold text-5xl text-black">{summary.summary?.applying}</div>
                        </div>
                        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                          <div className="font-bold text-base text-black">Submitted</div>
                          <div className="font-bold text-5xl text-black">{summary.summary?.submitted}</div>
                        </div>
                        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                          <div className="font-bold text-base text-black">Approved</div>
                          <div className="font-bold text-5xl text-black">{summary.summary?.approved}</div>
                        </div>
                        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                          <div className="font-bold text-base text-black">Inelligible</div>
                          <div className="font-bold text-5xl text-black">{summary.summary?.rejected}</div>
                        </div>
                        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                          <div className="font-bold text-base text-black">Total</div>
                          <div className="font-bold text-5xl text-black">{totalCount}</div>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                    <h4 className="text-center">Earnings</h4>
                    <div
                      className={
                        'grid grid-cols-2 md:grid-cols-5 justify-items-center' +
                        (currentTab !== ReferralTabs.Summary ? ' hidden' : '')
                      }
                      hidden={currentTab !== ReferralTabs.Summary}>
                      <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                        <div className="font-bold text-base text-black">In Process</div>
                        <div className="font-bold text-5xl text-black">{summary.summary?.applying}</div>
                      </div>
                      <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                        <div className="font-bold text-base text-black">Next Disbursment</div>
                        <div className="font-bold text-5xl text-black">{summary.summary?.submitted}</div>
                      </div>
                      <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                        <div className="font-bold text-base text-black">Total Paid To Date</div>
                        <div className="font-bold text-5xl text-black">{summary.summary?.approved}</div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </TabPanel>
                <TabPanel value={ReferralTabs.Referrals}>
                  <ReferralList shouldQuery={ReferralTabs.Referrals === currentTab} />
                </TabPanel>
                <TabPanel value={ReferralTabs.Downline}>
                  <DownlineList downline={summary.downline} downlineRate={summary.downlineRate} />
                </TabPanel>
              </div>
            </Card>
          </div>
        </div>
        <Snackbar
          open={copySnackOpen}
          autoHideDuration={4000}
          onClose={() => setCopySnackOpen(false)}
          message="Referral URL copied to your clipboard!"
        />
      </div>
    </div>
  )
}

function formatRate(rate: number, type: 'percent' | 'flat') {
  if (type === 'percent') return `${rate}%`
  return `$${rate}`
}

const TabPanel: React.FC<{ value: ReferralTabs; children?: React.ReactNode }> = ({ value, children }) => {
  const currentTab = useAtomValue(_currentTab)
  if (value !== currentTab) {
    return null
  }

  return (
    <div className="justify-start items-stretch p-2 flex-1 flex" style={{ minWidth: 300, alignSelf: 'center' }}>
      {children}
    </div>
  )
}

const agreementText = (rate = 100, rateType: 'percent' | 'flat') => `
AFFILIATE AGREEMENT

1. Services. Affiliate (You) agrees to the following responsibilities and to provide services as follows
(“Services”):
1.1. Affiliate may bring potential clients to Company as opportunities for Company and its processor to file the FFCRA Self Employment Tax Credit on their behalf

2. Affiliate Personnel. Affiliate shall ensure that any and all of its representatives, employees, agents, staff, and subcontractors (“Personnel”) shall adhere to the terms described herein. In all cases, Affiliate shall conform to state and federal labor laws with regard to its Personnel. Affiliate expressly agrees to indemnify and hold Company harmless from any and all liability whatsoever associated therewith

Affiliate shall bear any and all costs or expenses incurred by Affiliate and/or its Personnel to perform the Services herein, including without limitation, insurance, travel expenses, mileage, office expenses, telephone, labor costs, and all related costs, if any. Affiliate will ensure that it, and all Personnel, shall have any and all insurance as required by law, including without limitation, worker’s compensation insurance

3. Independent Contractor Status; Relationship of the Parties. Affiliate represents and agrees that for State or Federal Income Tax purposes, Affiliate will be treated, paid and classified as an independent contractor, and therefore, Affiliate is solely responsible for the timely payment of all taxes for any amounts paid to Affiliate under this Agreement including, but not limited to, all federal, state, or local taxes

Affiliate further represents and agrees that the Company is under no obligation to withhold any amounts for taxes for Affiliate nor to inform Affiliate of any tax obligations, prepare any tax reports, or transfer any amounts for taxes. No part of Affiliate’s compensation will be subject to withholding by the Company for the payment of any social security, federal, state or any other employee payroll taxes

The Company will regularly report amounts paid to Affiliate as required by law

Except as otherwise provided, in no event shall the Company be liable in any manner for the acts and omissions of Affiliate or its Personnel including, without limitation, the failure of Affiliate to compensate its Personnel. In performance of its obligations and Services under this Agreement, Affiliate shall act and be contracted solely as an independent contractor of the Company. It is not the intent of the Parties to create under this Agreement a joint venture, partnership, business association or other form of business entity. Affiliate shall be free to perform services for other parties

4. Compensation

4.1. Affiliate shall be paid 
${formatRate(rate, rateType)}
for each application that is completed and after applicant has paid, once they receive their credit.

4.2. Affiliate shall be paid weekly the week following the receipt of total payment by the client

5. No Worker’s Compensation or Unemployment Insurance Benefits. Affiliate acknowledges and agrees, that as an independent contractor, neither it, nor its Personnel, are entitled to workers’ compensation coverage from the Company or unemployment insurance coverage from the Company and should an injury or period of unemployment occur during its performance of services under this Agreement, such claims will not be covered by the Company’s workers’ compensation policy or any program providing unemployment insurance benefits

Affiliate agrees to complete and sign any additional document required as further evidence of the fact that Affiliate has been engaged solely as an independent contractor and is not entitled to workers’ compensation coverage from the Company or unemployment insurance benefits

6. Work Materials. Affiliate is responsible to provide all necessary materials, including without limitation, a device such as a computer, tablet, or smartphone to access the Company’s portal, and to perform the Services

7. Code of Conduct. Affiliate agrees to be completely honest and ethical in all of its dealings with the Company, its affiliates, clients, customers, and government agencies. If any person complains to the Company because of Affiliate’s alleged dishonest, unethical, or improper conduct, the Company may terminate this Agreement, and Affiliate shall be liable and responsible to the Company for any and all fines, damages, attorneys’ fees and or costs incurred as a result of Affiliate’s actions or fraud. Affiliate agrees to comply with all laws, rules, regulations, or ordinances applicable to the Services that Affiliate performs under this Agreement and that Affiliate will review and make itself and its Personnel aware of said laws, rules, regulations, or ordinances before performing any services for the Company under this Agreement. ANY FRAUD OR CRIMINAL MISCONDUCT BY AFFILIATE WILL BE REPORTED TO LAW ENFORCEMENT. AFFILIATE UNDERSTANDS AND AGREES THAT IT WILL BE LIABLE TO THE COMPANY FOR ALL DAMAGE FROM FRAUD, FOR ALL NEGLIGENT ACTS, RECKLESS BEHAVIOR OR INTENTIONAL MISCONDUCT, WHETHER CRIMINAL OR CIVIL. Any misrepresentation as a result of dishonesty or unethical behavior may be considered sufficient cause for termination of this Agreement and other consequences as deemed necessary to remedy resulting damage. Termination of this Agreement will result in a loss of any unpaid or unearned Compensation

8. Termination. Affiliate agrees that this Agreement may be terminated as follows: 
8.1. Termination. Affiliate may terminate this Agreement per the terms specified in this section at any time by 30 days’ written notice to the other Party

8.2. Compensation Pay on Termination. If Affiliate terminates this Agreement for any reason, then Affiliate will be entitled to any earned and unpaid Compensation from Clients it obtained up to and including the date of termination according to the Compensation terms of this Agreement, provided, however, that in the event Affiliate violates the preceding section herein concerning Code of Conduct, then Affiliate shall only be entitled to Compensation earned up to the time of the violation and shall not be entitled to any Compensation for Services performed after the time of the violation

9. Choice of Law, Jurisdiction and Venue. The Parties agree that this Agreement shall be deemed to have been made and entered into in the State of Utah and that the law of the State of Utah shall govern this Agreement. Subject to the Agreement regarding arbitration, any unresolved disputes arising from this Agreement shall be adjudicated in the District Court of the State of Utah

10. Confidentiality

10.1. Proprietary Rights. The Company reserves and maintains ownership of all copyrights, trademarks, designs, artwork, verbal affiliations, and visual affiliations, with regard to the content and representation of the Company

Any and all information in relation to marketing, sales, processing, accounting, and ideas of the Company’s services is strictly confidential and representation or claims, other than those authorized by the Company, made by Affiliate is strictly prohibited

10.2. Affiliate hereby recognize that the Company has developed and will continue to develop a substantial business, which includes the ownership and possession of Intellectual Property and Proprietary Structures and Systems, numerous procedures, technologies, files and client lists and other confidential information regarding clients and projects (“Confidential Information”), all of which are confidential in nature and which derive independent economic value, and actual potential from not being generally known to, and not being readily ascertainable through proper means by, other persons who can obtain economic value from its disclosure all of which are and will continue to be of great and unique value to the Company, and which the Company will make reasonable efforts to maintain its secrecy and that by reason of Affiliate’s relationship with the Company, Affiliate may acquire such Confidential Information regarding the clients and projects of the Company. The Company has developed an exclusive market for its products and has spent, and will continue to spend, considerable time in marketing its products nationally

10.3. In consideration of the Company’s disclosure of Confidential Information to Affiliate, it hereby agrees as follows:
10.3.1. Affiliate shall hold and maintain the Confidential Information in strictest confidence and in trust for the sole and exclusive benefit of the Company

10.3.2. Affiliate shall not, without the prior written approval of the Company, use for its own benefit, publish or otherwise disclose to others (excluding its Personnel), or permit the use by others (excluding its Personnel) for their benefit or to the detriment of the Company, any of the Confidential Information, unless required by law

10.3.3. Affiliate shall carefully restrict access to Confidential Information and shall allow access only to those who clearly require it in order to fulfill the obligations to the Company hereunder. Affiliate further warrants and represents that it shall advise each person to whom they provide access to any of the Confidential Information under the foregoing sentence that such persons are strictly prohibited from making any use, publishing or otherwise disclosing, or permitting others to use for their benefit or to the detriment of the Company, any of the Confidential Information

10.3.4. Affiliate shall take all reasonable and necessary action to protect the confidentiality of the Confidential Information, except for the necessary disclosure under the preceding Paragraph, and agrees to indemnify the Company against any and all losses, damages, claims or expenses incurred or suffered by the Company as a result of Affiliate’s breach of this Agreement

10.3.5. The Company has a legitimate and protectable interest in its Intellectual Property and Confidential Information. It is possible that

Affiliate may draw away customers from the Company if Affiliate was permitted to compete or act in any manner adverse to the Company. The Company desires to prohibit Affiliate from divulging to others, representing, or using for Affiliate’s own benefit Proprietary Structures and Systems, clients, customers, products, technologies, processes, or matters associated with said business of the Company which Affiliate learns as a result of Affiliate’s relationship with the Company. The Company desires to prohibit Affiliate unauthorized competition with said business of the Company, and Affiliate agrees with the Company as to the proprietary nature and confidentiality of the information regarding the clients, customers, products, processes and other matters of the Company, and as to the necessity for the Company prohibiting Affiliate unauthorized competition with the Company’s business;
10.3.6. Affiliate understands and acknowledges that any disclosure or misappropriation of any of the Confidential Information in violation of this Agreement may cause the Company irreparable harm, the amount of which may be difficult to ascertain and, therefore, agrees that the Company shall have the right to apply to a court of competent jurisdiction for an order restraining any such further disclosure or misappropriation and for such other relief as the Company shall deem appropriate. Such right of the Company is to be in addition to the remedies otherwise available to the Company at law or in equity

10.4. Affiliate shall return to the Company any and all records, notes, and other written, printed or tangible materials pertaining to the Confidential Information immediately upon the termination of this Agreement

11. Non-circumvention

11.1. Affiliate acknowledges and agrees that any contract for potential business including but not limited to; Services, potential vendors, vendors, potential clients, clients, potential suppliers, and suppliers that the Company represents to Affiliate shall not be approached by Affiliate without the express written permission of the Company. The permission must be in written form and signed by an officer of the Company and have not violated this Agreement. Furthermore, Affiliate agrees to operate in direct contact with the Company and shall not negotiate any Agreements with current vendors, service providers, their affiliates, or any of the Company’s contacts independently without the written consent of the Company

11.2. Affiliate expressly agrees that during the Term of this Agreement and for a period of one (1) year after the termination of this Agreement, Affiliate will not, or on behalf of any other person, business, partnership or entity, engage directly or indirectly, in the business of operating, owning, contracting with, working, carrying on a business or engage in any practice to develop, market, manufacture, design or market the Company’s services, or otherwise compete either directly or indirectly with the Company. Further, Affiliate agrees that it will not, for Affiliate, or on behalf of or in conjunction with any other person, partnership, business or entity, directly or indirectly, solicit, divert or attempt to solicit or divert any of the Company’s employees, contractors, representatives, affiliates, customers, or clients or the business or patronage of any such customers or clients or business contacts as previously mentioned. Affiliate agrees to keep in the utmost confidence and not to divulge to any party or entity other than those associated with the Company all trade secrets, and Confidential Information as herein stated. Affiliate agrees that such restriction of competition and confidentiality of information is absolutely necessary for the protection of the Company’s business

11.3. Affiliate hereby agrees that the promises contained herein are reasonable, and necessary for the protection of the Company’s business. Affiliate recognizes that the Company will suffer irreparable damage if, during Affiliate’s relationship with the Company, or at any time subsequent to the termination of such relationship, Affiliate should divulge any Confidential Information, wrongfully compete against the Company, or perform any act that is in violation of the terms of this Agreement. Affiliate further recognizes that it will be difficult, if not impossible, to compute the damage to the Company as a result of any such disclosure or unauthorized competition, and that, therefore, the Company is without adequate legal remedy. In such an event, Affiliate expressly agrees that the Company shall be entitled to apply to any court of competent jurisdiction to enjoin any breach, threatened or actual, compensatory, and other damages. Affiliate further acknowledges that Affiliate has full understanding of all of the terms of this paragraph and hereby agrees that should Affiliate decide to compete with the Company, Affiliate shall pay for all attorney’s fees as may be proven in addition to any other remedies, equitable or legal as may be available in a court of law


12. License. The Company hereby grants Affiliate a license to use its name, Technique Inside Sales, LLC, or “claim my credit/ claimmy.credit”, or a derivative thereof, during the Term of this Agreement. Affiliate agrees to use the name, Technique inside sales, LLC, or “claim my credit/ claimmy.credit” a derivative thereof, solely in connection with marketing and promoting of the Company’s business and Services as provided in the terms of this Agreement

13. Severability of Restrictive Clauses. Each clause contained in this Agreement is separate and distinct from every other clause set forth in each paragraph/section, and in the event that any of the clauses herein are declared invalid for any reason, the remaining clauses shall be considered separate, independent, and divisible, and shall remain fully enforceable

14. Binding Arbitration. Any unresolved dispute under this Agreement may, at the election of the Company, be referred to and settled by means of binding arbitration in accordance with both the substantive and procedural laws of Chapter 11 of Title 78B of the Utah Code (“Utah Uniform Arbitration Act”) and the National Rules for the Resolution of Employment Disputes of the American Arbitration Association (the “Arbitration Rules”). In the event of conflict or inconsistency between or among the Utah Uniform Arbitration Act, the Arbitration Rules, and/or the terms and conditions of this Agreement, such conflict or inconsistency shall be resolved by giving precedence in the following order: (i) this Agreement; (ii) Utah Uniform Arbitration Act (iii) the Arbitration Rules. Judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The Company may commence the arbitration process at any stage of the legal or dispute resolution process by giving the other party written notice of the demand for arbitration. The dispute will be submitted to an independent arbitrator as is agreeable to the Company and Affiliate within 60 days after the Affiliate’s receipt of the notice of demand for arbitration. The Parties hereto agree to equally share any Arbitration filing fee to refer the dispute to the American Arbitration Association or other independent Arbitrator. The Arbitrator shall have the power and discretion to award any portion of the cost of this fee to the prevailing party

The arbitration shall be conducted in Salt Lake County or Utah County, in the State of Utah. Nothing in this Section shall prohibit any Party from seeking emergency equitable relief pending Arbitration. In addition to the right under the Rules to petition the court for provisional relief, Affiliate agrees that any Party may also petition the court for injunctive relief where either Party alleges or claims a violation of any section of this Agreement or any other agreement regarding trade secrets, confidential information, non-solicitation, or noncompetition. In the eventeither the Company or Affiliate seeks injunctive relief, the prevailing party will beentitled to recover reasonable costs and attorneys’ fees. Furthermore, the prevailingparty in any dispute between the Parties concerning the terms and provisions ofthis Agreement shall be entitled to collected from the other party all fees, costs,and expenses incurred in such dispute, including without limitation, suchreasonable attorneys’ fees and expenses

15. Affiliate Representations and Warranties. Affiliate represents, warrants, and covenants to the Company that:
15.1. Affiliate has the legal power, right and authority to enter into this Agreement and the instruments to be executed by Affiliate pursuant to this Agreement and to consummate the transactions contemplated hereby

15.2. All requisite corporate action has been taken by Affiliate in connection with Affiliate’s execution of this Agreement and the instruments to be executed by Affiliate pursuant to this Agreement and the consummation of the transactions contemplated hereby

15.3. The individuals executing this Agreement and the instruments to be executed by Affiliate pursuant to this Agreement on behalf of Affiliate, have the legal power, right and actual authority to bind Affiliate to the terms and conditions of this Agreement and such instruments

15.4. Neither the execution of this Agreement nor the consummation of the transactions contemplated hereby shall result in a breach of or constitute a default under any agreement, document, instrument, or other obligation to which Affiliate is a party or by which Affiliate may be bound, or under any law, statute, ordinance, rule, governmental regulation or any writ, injunction, order or decree of any court or governmental body, applicable to Affiliate

15.5. There is no claim, action, litigation, arbitration, material dispute or other proceeding pending against Affiliate which relates to the services contemplated hereby except as disclosed in writing to the Company, and to Affiliate’s actual knowledge, there is currently no governmental investigation, threatened litigation or arbitration proceedings to which Affiliate is, or would be, a party which relates to or would relate to the services herein


15.6. No attachments, execution proceedings, assignments for the benefit of creditors, insolvency, bankruptcy, reorganization, or other proceedings are pending or threatened against Affiliate nor are any such proceedings contemplated by Affiliate

15.7. Affiliate is a duly constituted and validly existing entity under the laws of the state(s) where it operates, duly qualified to do business in the state(s) in which the services are to be performed pursuant to this Agreement and has the full power to carry out the services and transactions contemplated by this Agreement

15.8. Affiliate certifies and agrees that Affiliate is fully familiar with all terms, conditions, and obligations of this Agreement

16. Miscellaneous Provisions

16.1. Affiliate agrees to keep all of the terms of this Agreement confidential, except that certain terms may be confidentially disclosed to officers and employees of the Company as required in the ordinary course of business

16.2. This Agreement shall be binding upon, and inure to the benefit of, the successors, executors, heirs, representatives, administrators and permitted assigns of the Parties hereto. Affiliate shall have no right to assign this Agreement by operation of law or otherwise. This Agreement and any Attachments hereto may not be amended or modified otherwise than by a written agreement executed by the Parties hereto

16.3. Any term or provision of this Agreement may be amended, and the observance of any term of this Agreement may be waived, only by a writing signed by the Party to be bound. The failure of the Company at any time to enforce performance by Affiliate of any provisions of this Agreement shall in no way affect the Company’s rights thereafter to enforce the same, nor shall the waiver by the Company of any breach of any provision hereof be held to be a waiver of any other breach of the same or any other provision

16.4. Affiliate agrees to indemnify and hold harmless the Company and its directors, officers and employees from and against all taxes, losses, damages, liabilities, costs and expenses, including attorneys’ fees and other legal expenses, arising directly or indirectly from or in connection with (i) any negligent, reckless or intentionally wrongful act of Affiliate or its

Personnel; (ii) a determination by a court or agency that Affiliate is not an independent contractor; (iii) any breach by Affiliate or its Personnel of any of the covenants, terms or conditions contained in this Agreement or in any other policies of the Company as may be in effect from time to time; or (iv) any failure of Affiliate or its Personnel to perform the Services in accordance with all applicable laws, rules and regulations. Any unpaid Compensation or monies owed to Affiliate may be used to cover claims for damages. The prevailing party in any action, suit, or proceeding brought to enforce the terms of this Agreement shall be entitled to its costs and expenses, including attorney fees, from the other party

16.5. The language in all parts of this Agreement shall in all cases be construed simply, as a whole and in accordance with its fair meaning and not strictly for or against any Party. The captions of this Agreement are not part of the provisions hereof and shall have no force or effect. The Parties acknowledge and agree that this Agreement has been negotiated by the Parties, that each Party has been given the opportunity to independently review this Agreement with legal counsel, and that each Party has the requisite experience and sophistication to understand, interpret and agree to the particular language of the provisions hereof. Accordingly, in the event of an ambiguity in or dispute regarding the interpretation of this Agreement, this Agreement shall not be interpreted or construed against the Party preparing it

16.6. Entire Agreement. This Agreement contains the entire agreement and understanding related to this specific position, between Affiliate and the Company for the Services specified herein and supersedes any prior or cotemporaneous agreements, understandings, communications, offers, representations, warranties, or commitments by or on behalf of theCompany (oral or written)

`
