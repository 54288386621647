import { userTokenAtom } from '@/app/atoms'
import useQueryUser from '@/app/hooks/useQueryUser'
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { title } from 'case'
import { useAtomValue } from 'jotai'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Referral, ReferralStatus } from '../FormTypes'
interface ReferralListProps {
  shouldQuery?: boolean
}
const referralStatusList = Object.keys(ReferralStatus).filter((val) => isNaN(Number(val)))

const getRandomString = (length = 6) => Math.random().toString(20).substring(2, length)
const getRandomStatus = () => referralStatusList[Math.floor(Math.random() * 4)]
const getRows = (length = 25) => {
  const rows: Array<Referral> = []
  for (let i = 0; i < length; i++) {
    rows.push({
      name: `${getRandomString()} ${getRandomString()[0]}`,
      status: getRandomStatus() as Referral['status'],
    })
  }
  return rows
}

const ReferralList: React.FC<ReferralListProps> = ({ shouldQuery }) => {
  const userToken = useAtomValue(userTokenAtom)
  const { getUserReferralList } = useQueryUser()
  const { data, isLoading, isFetched } = useQuery('referral-list', async () => getUserReferralList(userToken), {
    enabled: !!userToken && !!shouldQuery,
  })

  const columns: GridColDef[] = useMemo(() => {
    if (!isFetched || !data?.body?.referrals?.some((ref) => !!ref.customer_id)) {
      return [
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'status', headerName: 'Status', width: 130 },
      ]
    } else {
      return [
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'status', headerName: 'Status', width: 130 },
        { field: 'customer_id', headerName: 'Customer Id', width: 130 },
        { field: 'source_tracking_id', headerName: 'Source Tracking Id', width: 170 },
      ]
    }
  }, [isFetched])
  const [filter, setFilter] = useState<Referral['status'] | ''>('')

  const filteredData = useMemo(() => {
    if (!isFetched || !data || !data.success) {
      return []
    }
    const list = data.body?.referrals.map((ref) => ({
      ...ref,
      status: title(ref.status.toLowerCase() === 'rejected' ? 'Inelligible' : ref.status),
    }))
    if (filter !== '') {
      return list.filter((row) => row.status.toLowerCase() === filter.toLowerCase())
    }
    return list
  }, [filter, data?.body?.referrals, isFetched])

  // const testData: Referral[] = useMemo(() => {
  //   return getRows(40)
  // }, [])

  // const _data = useMemo(() => {
  //   const list = testData.map((ref) => ({
  //     ...ref,
  //     status: title(ref.status.toLowerCase() === 'rejected' ? 'Inelligible' : ref.status),
  //   }))
  //   if (filter !== '') {
  //     return list.filter((row) => row.status.toLowerCase() === filter.toLowerCase())
  //   }
  //   return list
  // }, [filter, testData])

  let rowIndex = -1
  return (
    <div className={'flex flex-1 flex-col'}>
      <div className={'flex flex-0 flex-col items-end'}>
        <FormControl>
          <InputLabel>Filter Status</InputLabel>
          <Select value={filter} label="Filter Status" onChange={(e) => setFilter(e.target.value as typeof filter)}>
            <MenuItem value={''}>-</MenuItem>
            <MenuItem value={'Applying'}>Applying</MenuItem>
            <MenuItem value={'Submitted'}>Submitted</MenuItem>
            <MenuItem value={'Approved'}>Approved</MenuItem>
            <MenuItem value={'Inelligible'}>Inelligible</MenuItem>
          </Select>
        </FormControl>
      </div>
      {filteredData.length > 0 ? (
        <DataGrid
          getRowId={(row) => {
            rowIndex++
            return rowIndex
          }}
          disableColumnFilter
          disableColumnMenu
          rows={filteredData}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { page: 0, pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 50]}
        />
      ) : (
        <Typography>You do not have any referrals.</Typography>
      )}
    </div>
  )
}

export default ReferralList
